import React from 'react';
import {
   DispatchForm,
   GetAQuoteForm,
   Layout,
   PageHeader,
   Seo,
} from '../components';
import { graphql } from 'gatsby';

const DispatchQuote = ({ data }) => {
   return (
      <Layout>
         <Seo title='Get a Dispatch Quote' />
         <PageHeader
            title='Get a Dispatch Quote'
            bgImg={data.titleImage.childImageSharp.gatsbyImageData}
         />
         <DispatchForm />
      </Layout>
   );
};

export const query = graphql`
   {
      titleImage: file(name: { eq: "contact-header" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
         }
      }
   }
`;

export default DispatchQuote;
